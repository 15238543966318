import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const VideoPromocionalPage = () => {
    const data = useStaticQuery(graphql`
    query {
      videosCorporativosImage1: file(relativePath: { eq: "images/sections/productora-audiovisual/videos-corporativos/por-que-un-video-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      videosCorporativosImage2: file(relativePath: { eq: "images/sections/productora-audiovisual/videos-corporativos/videos-que-realizamos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      videosCorporativosImage3: file(relativePath: { eq: "images/sections/productora-audiovisual/videos-corporativos/ventajas-videos-corporativos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Videos Corporativos Empresas"
                description="Productora audiovisual en Barcelona experta en la creación de videos corporativos para empresas y videos promocionales."
            />

            <BannerPage
                title="Expertos en vídeos promocionales"
                text="Grabación, montaje y postproducción de vídeos para empresas"
                color="yellow"
            />

            <SectionTextImage
                title1="¿Por qué "
                title2="un vídeo corporativo?"
                text={<Fragment>
                    Hoy en día incluir dentro de tu estrategia de marketing de la empresa un vídeo es clave. Hay mucho consumo audiovisual, con lo que <strong>tener una pieza audiovisual presenta siempre una oportunidad más de venta</strong>. Además, un contenido de estas características tiene una aceptación muy alta.
                </Fragment>}
                image={data.videosCorporativosImage1.childImageSharp.fluid}
                imageAlt="¿Por qué crear un vídeo corporativo? - Agencia Vídeos Corporativos"
                color="yellow"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="¿Qué tipo de "
                title2="vídeos realizamos?"
                text={<Fragment>
                    En IBX somos expertos en la preproducción, producción y post producción de: <br /><br />
                    <ul className="text-left">
                        <li>Vídeos corporativos para empresas</li>
                        <li>Videos promocionales</li>
                        <li>Vídeos sobre presentación de producto</li>
                        <li>Vídeos de presentación de empresa</li>
                    </ul>
                </Fragment>}
                image={data.videosCorporativosImage2.childImageSharp.fluid}
                imageAlt="¿Qué tipo de vídeo realizamos en IBX? - Agencia Vídeos Corporativos"
                color="yellow"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Ventajas de un "
                title2="vídeo corporativo?"
                text={<Fragment>
                    Las <strong>ventajas que aportan las piezas audiovisuales</strong> a tu negocio son:<br /><br />
                    <ol className="text-left">
                        <li>Dan una imagen real de tu empresa</li>
                        <li>Transmite cercanía</li>
                        <li>Genera confianza y conocimiento de marca</li>
                        <li>Genera más visibilidad</li>
                    </ol>
                </Fragment>}
                image={data.videosCorporativosImage3.childImageSharp.fluid}
                imageAlt="¿Por qué crear un vídeo corporativo? - Agencia Vídeos Corporativos"
                color="yellow"
                containerTextRight={false}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="yellow"
                silverText="Ideal para pequeñas empresas"
                silverPrice="650"
                silverPriceCurrency="€"
                silverFeatures={["Brifing proyecto", "Storyboard", "Grabación de vídeo", "Edición de vídeo (1 min aprox)", "Con música libre de derechos y grafismo corporativo", "Entrega de todo el material en alta resolución", "Optimizado para medios digitales"]}
                silverException={[]}
                goldText="Ideal para empresas medianas"
                goldPrice="900"
                goldPriceCurrency="€"
                goldFeatures={["Brifing proyecto", "Storyboard", "Grabación de vídeo", "Edición de vídeo (1 min aprox) con música libre de derechos y grafismo corporativo", "Toma de fotografías de espacio y producto (20-30 fotos)", "Entrega de todo el material en alta resolución", "Optimizado para medios digitales"]}
                goldException={[]}
                diamondText="Ideal para grandes empresas"
                diamondPrice="1150"
                diamondPriceCurrency="€"
                diamondFeatures={["Brifing proyecto", "Storyboard", "Grabación de vídeo", "Edición de vídeo (1 min aprox) con música libre de derechos y grafismo corporativo", "Toma de fotografías de espacio y producto (20-30 fotos)", "Grabación con dron", "Entrega de todo el material en alta resolución y optimizado para medios digitales"]}
                diamondException={[]}
            />

            <CallToAction
                color="yellow"
                title="¡Creamos vídeos impresionantes!"
                text={<Fragment>
                    ¡Somos la <strong>productora audiovisual en Barcelona</strong> que estás buscando! <br />
                    ¿Creamos tu vídeo?
                </Fragment>}
                button="¡LLámanos!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default VideoPromocionalPage
